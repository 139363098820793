import { useMemo } from 'react';
import useGasFees from '../shared/useGasFees';
import { formatEther } from 'viem';

const FEE_SCALAR = 0;
const BASE_L1_GAS = 22676;
const BASE_L2_GAS = 1600000;

const EXTRA_MULTIPLIER = 1.5;

const useExecutionFee = () => {
  const { maxL1FeePerGas, maxFeePerGas } = useGasFees();

  const estimatedL1Gas = Math.floor(BASE_L1_GAS * FEE_SCALAR);
  const estimatedL2Gas = Math.floor(BASE_L2_GAS);

  return useMemo(() => {
    const estimatedL1GasEth = BigInt(maxL1FeePerGas) * BigInt(estimatedL1Gas);
    const estimatedL2GasEth = BigInt(maxFeePerGas ?? 0) * BigInt(estimatedL2Gas);

    const feeEstimate =
      Number(formatEther(estimatedL1GasEth)) + Number(formatEther(estimatedL2GasEth));

    return feeEstimate * EXTRA_MULTIPLIER;
  }, [maxL1FeePerGas, maxFeePerGas, estimatedL1Gas, estimatedL2Gas]);
};

export default useExecutionFee;
