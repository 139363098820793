import { ethereumClient } from '@/blockchain/configs/wagmi';
import { captureError } from '@/lib/sentry';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAccount } from 'wagmi';
import { usePublicClient } from 'wagmi';

// Define the fee structure
interface GasFees {
  maxPriorityFeePerGas: bigint;
  maxFeePerGas: bigint;
  maxL1FeePerGas: bigint;
  gasPrice: bigint;
}

const INITIAL_FEES: GasFees = {
  maxPriorityFeePerGas: 1000000n,
  maxFeePerGas: 1000500n,
  maxL1FeePerGas: 23000000000n,
  gasPrice: 0n,
};

const useGasFees = (): GasFees => {
  const { address } = useAccount();
  const client = usePublicClient();

  const updateFees = useCallback(async () => {
    try {
      const [{ maxFeePerGas, maxPriorityFeePerGas }, { maxFeePerGas: maxL1FeePerGas }, gasPrice] =
        await Promise.all([
          client.estimateFeesPerGas(),
          ethereumClient.estimateFeesPerGas(),
          client.getGasPrice(),
        ]);

      return {
        maxPriorityFeePerGas,
        maxFeePerGas,
        maxL1FeePerGas,
        gasPrice,
      };
    } catch (error) {
      captureError(error, { address, action: 'updateGasFees' });
      console.error('Error updating gas fees:', error);
    }
  }, [client]);

  const { data: fees } = useQuery({
    queryKey: ['gas-fees'],
    queryFn: updateFees,
    placeholderData: keepPreviousData,
    refetchInterval: 6 * 1_000,
  });

  return fees ?? INITIAL_FEES;
};

export default useGasFees;
